import { render, staticRenderFns } from "./addDesensitization.vue?vue&type=template&id=bdc7b996&scoped=true"
import script from "./addDesensitization.vue?vue&type=script&lang=js"
export * from "./addDesensitization.vue?vue&type=script&lang=js"
import style0 from "./addDesensitization.vue?vue&type=style&index=0&id=bdc7b996&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdc7b996",
  null
  
)

export default component.exports